import cookie from "js-cookie";
import json2xls from "json2xls";
import fs from "fs";
import filetype from "../utils/filetype";

export const checkIsFromDashboard = (params: URLSearchParams) => {
  if (params.has("isFromDashboard")) {
    return params.get("isFromDashboard") === "true";
  }

  return false;
};

export const inIframe = () => {
  //https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const getCookieFromBrowser = (key) => cookie.get(key);

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) return false;

  const rawCookie = req.headers.cookie
    .split(";")
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) return false;
  const [, token] = rawCookie.split("=");
  if (!token) return false;

  return token;
};

export const getCookies = (key, req) => {
  if (process.browser) return getCookieFromBrowser(key);
  return getCookieFromServer(key, req);
};

export const createCSV = (data) => {
  const array = typeof data !== "object" ? JSON.parse(data) : data;
  let str = "";
  let line = "";

  // header
  for (const index in array[0]) {
    const value = index + "";
    line += '"' + value.replace(/"/g, '""') + '"|';
  }
  line = line.slice(0, -1);
  str += line + "\r\n";

  // data
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      let value = "";
      if (index !== "value_dump") {
        value = array[i][index] + "";
        line += '"' + value.replace(/"/g, '""') + '"|';
      } else {
        value = JSON.stringify(array[i][index]);
        line += '"' + value + '"|';
      }
    }
    line = line.slice(0, -1);
    str += line + "\r\n";
  }
  return str;
};

export const createExcel = (data: any) => {
  const xls = json2xls(data);

  fs.writeFileSync("data.xlsx", xls, "binary");

  let file: any;

  file = fs.readFileSync("data.xlsx");

  fs.unlink("data.xlsx", (err) => {
    if (err) throw err;
    console.log("temp file was deleted");
  });

  return file;
};

export const contractFormat = (contract: string) => {
  return contract.replace(/^(.{3})(.{3})(.{4})(.*)$/, "$1 $2 $3 $4");
};

export const debounce = (func: Function, timeout?: number) => {
  let timer;
  return (...args) => {
    const next = () => func(...args);
    if (timer) clearTimeout(timer);
    timer = setTimeout(next, timeout > 0 ? timeout : 300);
  };
};

export const search = debounce((e, list, setFiltered) => {
  const x = e.target.value;
  const filteredSuggestions = list.filter(
    (suggestion) => suggestion.toLowerCase().indexOf(x.toLowerCase()) > -1
  );
  setFiltered(filteredSuggestions);
});

export const attachment = (file: any, name: string) => {
  if (file) {
    let base64Image = file.split(";base64,");
    let mimeType = base64Image[0];
    let encodedFile = base64Image[1];
    let extension = filetype(mimeType);

    let attach = [
      {
        data: `${encodedFile}`,
        filename: `${name + extension}`,
        description: "inline attachment",
      },
    ];

    return attach;
  } else {
    return "";
  }
};

export const htmlEncode = (str) => {
  return String(str).replace(/[^\w. ]/gi, function (c) {
    return "&#" + c.charCodeAt(0) + ";";
  });
};
